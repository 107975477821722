/**
 * CONTENTS
 *
 * VARIABLES
 * Colors........... All website colors
 * Themes........... Themes for clinic
 * Typography....... Font-family, font-size, line-height
 * Buttons.......... Buttons informations
 * Form............. Input, select style
 * Media Queries ... Breakpoint for responsive
 * Grid ............ Grid col and gutter
 */

/*------------------------------------*\
    #FONTS
\*------------------------------------*/
$font-montserrat: 'Montserrat', sans-serif;
$font-roboto: Roboto, 'Helvetica Neue', sans-serif;

$mat-medium: 'max-width: 1280px';

/*------------------------------------*\
    #COLORS
\*------------------------------------*/
$c-primary:       #e3002b;
$c-primary-light: #FF6161;

$c-secondary:            #7aaddc;
$c-med-secondary:        #506E80;
$c-secondary-btn:        #708A99;
$c-secondary-light:      #84cdff;
$c-secondary-lightest:   #D1EDFF;

$c-warn:          #ff5e23;
$c-warn-light:    #ff8e65;
$c-error:         #ff310c;

$c-grey:          #989fa5;
$c-medgray:       #a7afbb;
$c-grey-btn:      #D8D9DC;
$c-grey-light:    #eeeeee;
$c-grey-dark:     #333333;

$c-validated:   #1e90ff;
$c-check:  #33D334;
$c-green:       #8ad1ba;
$c-pink:        #FF5DEA;

$bg-color:    #f6f7fb;
$border-color: #dee2e6;
$text-color:  rgba(0,0,0,.87);

$c-input:                 #f4feff;
$c-input-text:            #404040;
$c-input-focus:           white;
$c-input-text-focus:      $text-color;
$c-input-disabled:        #ececec;
$c-input-text-disabled:   #8e8e8e;
$c-border-gray :          #DEE2E6;

/* Box Shadow */
$box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2);
$text-shadow: 1px 2px 2px $border-color;


/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/
$fz-base: 1.6em;

/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
$btn-radius:      0;
$btn-padding:     0.7rem 2rem;
$btn-border:      1px solid transparent;
$btn-minwidth:    15rem;


/*------------------------------------*\
    #FORMS
\*------------------------------------*/
$input-bg:          transparent;
$input-radius:      0;
$input-padding:     .5rem;
$input-border:      .1rem solid $c-grey-light;

/*------------------------------------*\
    #MEDIA-QUERIES
\*------------------------------------*/
// Extra small screen / phone
$screen-xs:                  30em !default;
$screen-xs-min:              $screen-xs !default;

// Small screen / tablet
$screen-sm:                  48em !default;
$screen-sm-min:              $screen-sm !default;

// Medium screen / desktop
$screen-md:                  62em !default;
$screen-md-min:              $screen-md !default;

// Large screen / wide desktop
$screen-lg:                  75em !default;
$screen-lg-min:              $screen-lg !default;

// X Large screen / x wide desktop
$screen-xlg:                  87.5em !default;
$screen-xlg-min:              $screen-lg !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;


/*------------------------------------*\
    #MEDIA QUERIES
\*------------------------------------*/
// To enable support for browsers that do not support @media queries,
// (IE <= 8, Firefox <= 3, Opera <= 9) set $mq-responsive to false
// Create a separate stylesheet served exclusively to these browsers,
// meaning @media queries will be rasterized, relying on the cascade itself
$mq-responsive: true;

$mq-base-font-size: $fz-base;

// Name your breakpoints in a way that creates a ubiquitous language
// across team members. It will improve communication between
// stakeholders, designers, developers, and testers.
$mq-breakpoints: (
        mobile:  $screen-xs,
        tablet:  $screen-sm,
        desktop: $screen-md,
        wide:    $screen-lg,
        xwide:   $screen-xlg,

    // Tweakpoints
        phablet: 37.5em,
        mobileLandscape: 30em
);

// Define the breakpoint from the $mq-breakpoints list that should
// be used as the target width when outputting a static stylesheet
// (i.e. when $mq-responsive is set to 'false').
$mq-static-breakpoint: desktop;

// If you want to display the currently active breakpoint in the top
// right corner of your site during development, add the breakpoints
// to this list, ordered by width, e.g. (mobile, tablet, desktop).
$mq-show-breakpoints: ();

// Media queries
$mat-xsmall: 'max-width: 600px';
$mat-small: 'max-width: 960px';

/*------------------------------------*\
    #GRID
\*------------------------------------*/

//** Number of columns in the grid.
$grid-columns:              12 !default;
$grid-gutter-width:         20px !default;


//== Container sizes
// Small screen / tablet
$container-tablet:             720px !default;
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            960px !default;
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      1160px !default;
$container-lg:                 $container-large-desktop !default;

// Container max-width
$container-mw:                 1920px;
$container:                    1260px;


/*
 * Unstyled list - remove margin, padding and list type
 */
%unstyled-list,
.unstyled-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/* Make hidden text accessible by screen readers */
%sr-only,
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
 * Clearfix: contain floats
 */
%clearfix:after,
%clearfix:before,
.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}

%clearfix:after,
.clearfix:after {
  clear: both;
}
