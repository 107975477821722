@import "../utilities/variables";
@import "../utilities/sass-mq";

#body {
  .main-header-content {
    &.-mobile {
      display: block;
      background-color: rgba($c-primary, .8);
      width: 65%;
      margin: 0 auto;
      padding: 1rem;
      p {
        color: $c-grey-light;
        font-size: 13px;
        margin-bottom: .5rem;
      }
      a {
        color: #fff;
        font-size: 11px;
        margin-bottom: .5rem;
      }

      @include mq($from: tablet) {
        display: none;
      }
    }
  }
}

#main-header {
  background-color: $bg-color;
  background-image: url("../../../medias/img/banner_autodiag.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 41vh;
  width: 100%;
  box-sizing: border-box;
  .row {
    height: 41vh;
    p {
      color: $c-grey-light;
    }
    h1 {
      color: $text-color;
      font-weight: bold;
      padding-left: 5rem;
      padding-top: 6rem;
      font-size: 20px;
      width: 90%;
    }
    .main-header-content {
      display: none;
    }
  }

  &.-infos {
    background-image: url("../../../medias/img/banner_prevention_infos.png");
    h1 {
      color: $c-grey-light;
    }
    .row {
      .main-header-content {
        display: block;
        background-color: transparent;
        width: inherit;
        margin: 0 auto;
      }
      h1 {
        text-align: center;
        padding-left: 0;
        padding-top: 0;
        font-size: 33px;
      }
    }

  }

  @include mq($from: phablet) {
    //&.-infos {
    //  background-image: url("../../../medias/img/banner_prevention_infos.png");
    .row {
      h1 {
        padding-left: 10rem;
      }
      .main-header-content {
        div {
          padding: .5rem;
        }
      }
    }
    //}

  }

  @include mq($from: tablet) {
    .row {
      h1 {
        font-size: 25px;
        padding-top: 2rem;
      }
      .main-header-content {
        display: block;
        background-color: rgba($c-primary, .8);
        width: 65%;
        margin: 0 auto;
        padding-top: 2rem;
        p {
          font-size: 13px;
          margin-bottom: .5rem;
        }
        a {
          color: #fff;
          font-size: 11px;
          margin-bottom: .5rem;
        }
      }
    }

  }
  @include mq($from: desktop) {
    .row {
      h1 {
        width: inherit;
        padding-left: 20rem;
      }
      div {
        height: 41vh;
      }
    }
    &.-infos {
      .row {
        .main-header-content {
          background-color: rgba($c-primary, .8);
          p {
            font-size: 14px;
          }
        }
        h1 {
          padding: 2rem;
        }
      }
    }
  }
  @include mq($from: wide) {
    .row {
      .col-lg-7 {
        padding: 4rem;
      }
      h1 {
        font-size: 35px;
        padding-top: 0;
      }
      .main-header-content {
        div {
          padding: 2rem;
        }
        p {
          font-size: 15px;
          a {
            font-size: 13px;
          }
        }
      }
    }
    &.-infos {
      .row {
        h1 {
          font-size: 45px;
        }
      }
    }
  }
}