@import '../utilities/variables';

.block {
    &.-grey {
        background: $bg-color;
    }
    h2 {
        margin-top: 1rem;
        text-align: center;
    }

}
.panel {
    background-color: #fff;
    padding: 1rem;
    margin-bottom: 2rem;
    //@extend .z-depth-1;

    &.-primary-light {
        background-color: $c-primary-light;
        color: #fff;
    }
    &.-secondary {
        background: $c-secondary;
        color: #fff;
    }

    &.-flex {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &.-between {
        justify-content: space-between;
    }

    &.-title {
        margin: 0;
    }
}