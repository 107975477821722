@import '../utilities/variables';

#body {
  .btn {
    border: solid 1px rgba($c-primary, .5);
    color: $c-primary;
    background: #fff;
    transition: color 0.2s ease-out;
    margin: .5rem;
    border-radius: 0;
    &:not([disabled]):hover {
      color: #fff;
      background: $c-primary;
      text-decoration: none;
    }
    &.-table {
      //display: table-row;
      //padding: 0;
      font-size: inherit;
    }

    &.-no-border {
      border: none;
      background: none;
      color: inherit;
      &:not([disabled]):hover {
        color: inherit;
        background: none;
      }
    }

    &.-primary {
      border: solid 1px rgba($c-primary, .5);
      color: #fff;
      background: $c-primary;
      &.-opacity {
        background: rgba($c-primary, 0.8);
      }
      &:not([disabled]):hover {
        color: $c-primary;
        background: #fff;
        &.-opacity {
          background: rgba(#fff, 0.8);
        }
      }
    }

    &.-primary-light {
      border: solid 1px rgba($c-primary-light, .5);
      color: $c-primary-light;
      background: #fff;
      &:not([disabled]):hover {
        color: #fff;
        background: $c-primary-light;
      }
    }
  }

  .div-btn-center {
    text-align: center;
    margin: auto;
    background: #fff;
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 500;
  }

  .div-btn-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;

    span {
      text-transform: uppercase;
      color: $c-grey;
      margin-right: 1rem;
    }

  }

  .index-form-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    div {
      display: flex;
      align-items: baseline;
    }
  }

  // Remove blue border from css custom-styled button in Chrome
  button {
    &:focus, &:hover {
      outline: 0;
    }
    &:active {
      outline: none;
      border: none;
    }
  }
}
