@import '../utilities/variables';
/*------------------------------------*\
    #LANGUAGE SWITCHER
\*------------------------------------*/

.language-switcher {
    position: relative;
    text-align: right;
    display: flex;
    align-items: center;
    font-size: 13px;

    &__trigger {
        @extend .btn;
        position: relative;
        background-color: transparent;
        color: #fff;
        padding: .5rem 1.5rem .5rem .9rem;
        transition: color .3s;
        min-width: unset;
        font-size: 13px;

        &.-dark {
            color: $c-grey-dark;
        }
        &:after {
            content: '';
            position: absolute;
            right: .5rem;
            top: 50%;
            transform: translateY(-50%) rotate(180deg);
            width: .7rem;
            height: .7rem;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj4NCjxwYXRoIGZpbGw9IiMyNDUzNkUiIGQ9Ik0yNTYuMSwyMTQuMWwxNzQsMTY3YzQsNCwxMSw0LDE2LDBsMzAtMzBjNS00LDUtMTEsMS0xNmwtMjEzLTIwNGMtMi0yLTUtMy04LTNzLTYsMS04LDNsLTIxMywyMDQNCgljLTQsNS00LDEyLDAsMTZsMzEsMzBjNCw0LDExLDQsMTYsMEwyNTYuMSwyMTQuMXoiLz4NCjwvc3ZnPg0K');
            fill: #fff;
            background-position: center center;
            transition: transform 250ms ease-in, background .3s;
            font-weight: bold;
        }

        &[aria-expanded="true"] {
            &:after {
                transform: translateY(-50%);
            }
        }

        &:hover, &:focus {
            opacity: .7;
            outline: none;

            &:after {
                background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj4NCjxwYXRoIGZpbGw9IiNCREQ0MkEiIGQ9Ik0yNTYuMSwyMTQuMWwxNzQsMTY3YzQsNCwxMSw0LDE2LDBsMzAtMzBjNS00LDUtMTEsMS0xNmwtMjEzLTIwNGMtMi0yLTUtMy04LTNzLTYsMS04LDNsLTIxMywyMDQNCgljLTQsNS00LDEyLDAsMTZsMzEsMzBjNCw0LDExLDQsMTYsMEwyNTYuMSwyMTQuMXoiLz4NCjwvc3ZnPg0K');
            }
        }
    }

    &__list {
        @extend %unstyled-list;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 100;
        text-align: center;
        background-color: #fff;

        &[aria-hidden="true"] {
            display: none;
        }

        a {
            display: block;
            background-color: transparent;
            padding: .5rem 0;
            text-decoration: none;
            font-weight: bold;

            &:hover,
            &:focus {
                background-color: $c-grey-light;
                text-decoration: none;
            }
        }
    }
}