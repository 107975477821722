/**
 * CONTENTS
 *
 * VARIABLES
 * Colors........... All website colors
 * Themes........... Themes for clinic
 * Typography....... Font-family, font-size, line-height
 * Buttons.......... Buttons informations
 * Form............. Input, select style
 * Media Queries ... Breakpoint for responsive
 * Grid ............ Grid col and gutter
 */
/*------------------------------------*\
    #FONTS
\*------------------------------------*/
/*------------------------------------*\
    #COLORS
\*------------------------------------*/
/* Box Shadow */
/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/
/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    #FORMS
\*------------------------------------*/
/*------------------------------------*\
    #MEDIA-QUERIES
\*------------------------------------*/
/*------------------------------------*\
    #MEDIA QUERIES
\*------------------------------------*/
/*------------------------------------*\
    #GRID
\*------------------------------------*/
/*
 * Unstyled list - remove margin, padding and list type
 */
.language-switcher__list,
.unstyled-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/* Make hidden text accessible by screen readers */

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
 * Clearfix: contain floats
 */

.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}


.clearfix:after {
  clear: both;
}

#body .main-header-content.-mobile {
  display: block;
  background-color: rgba(227, 0, 43, 0.8);
  width: 65%;
  margin: 0 auto;
  padding: 1rem;
}

#body .main-header-content.-mobile p {
  color: #eeeeee;
  font-size: 13px;
  margin-bottom: .5rem;
}

#body .main-header-content.-mobile a {
  color: #fff;
  font-size: 11px;
  margin-bottom: .5rem;
}

@media (min-width: 48em) {
  #body .main-header-content.-mobile {
    display: none;
  }
}

#main-header {
  background-color: #f6f7fb;
  background-image: url("../../../medias/img/banner_autodiag.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 41vh;
  width: 100%;
  box-sizing: border-box;
}

#main-header .row {
  height: 41vh;
}

#main-header .row p {
  color: #eeeeee;
}

#main-header .row h1 {
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
  padding-left: 5rem;
  padding-top: 6rem;
  font-size: 20px;
  width: 90%;
}

#main-header .row .main-header-content {
  display: none;
}

#main-header.-infos {
  background-image: url("../../../medias/img/banner_prevention_infos.png");
}

#main-header.-infos h1 {
  color: #eeeeee;
}

#main-header.-infos .row .main-header-content {
  display: block;
  background-color: transparent;
  width: inherit;
  margin: 0 auto;
}

#main-header.-infos .row h1 {
  text-align: center;
  padding-left: 0;
  padding-top: 0;
  font-size: 33px;
}

@media (min-width: 37.5em) {
  #main-header .row h1 {
    padding-left: 10rem;
  }
  #main-header .row .main-header-content div {
    padding: .5rem;
  }
}

@media (min-width: 48em) {
  #main-header .row h1 {
    font-size: 25px;
    padding-top: 2rem;
  }
  #main-header .row .main-header-content {
    display: block;
    background-color: rgba(227, 0, 43, 0.8);
    width: 65%;
    margin: 0 auto;
    padding-top: 2rem;
  }
  #main-header .row .main-header-content p {
    font-size: 13px;
    margin-bottom: .5rem;
  }
  #main-header .row .main-header-content a {
    color: #fff;
    font-size: 11px;
    margin-bottom: .5rem;
  }
}

@media (min-width: 62em) {
  #main-header .row h1 {
    width: inherit;
    padding-left: 20rem;
  }
  #main-header .row div {
    height: 41vh;
  }
  #main-header.-infos .row .main-header-content {
    background-color: rgba(227, 0, 43, 0.8);
  }
  #main-header.-infos .row .main-header-content p {
    font-size: 14px;
  }
  #main-header.-infos .row h1 {
    padding: 2rem;
  }
}

@media (min-width: 75em) {
  #main-header .row .col-lg-7 {
    padding: 4rem;
  }
  #main-header .row h1 {
    font-size: 35px;
    padding-top: 0;
  }
  #main-header .row .main-header-content div {
    padding: 2rem;
  }
  #main-header .row .main-header-content p {
    font-size: 15px;
  }
  #main-header .row .main-header-content p a {
    font-size: 13px;
  }
  #main-header.-infos .row h1 {
    font-size: 45px;
  }
}

/**
 * CONTENTS
 *
 * VARIABLES
 * Colors........... All website colors
 * Themes........... Themes for clinic
 * Typography....... Font-family, font-size, line-height
 * Buttons.......... Buttons informations
 * Form............. Input, select style
 * Media Queries ... Breakpoint for responsive
 * Grid ............ Grid col and gutter
 */
/*------------------------------------*\
    #FONTS
\*------------------------------------*/
/*------------------------------------*\
    #COLORS
\*------------------------------------*/
/* Box Shadow */
/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/
/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    #FORMS
\*------------------------------------*/
/*------------------------------------*\
    #MEDIA-QUERIES
\*------------------------------------*/
/*------------------------------------*\
    #MEDIA QUERIES
\*------------------------------------*/
/*------------------------------------*\
    #GRID
\*------------------------------------*/
/*
 * Unstyled list - remove margin, padding and list type
 */
.language-switcher__list,
.unstyled-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/* Make hidden text accessible by screen readers */

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
 * Clearfix: contain floats
 */

.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}


.clearfix:after {
  clear: both;
}

.block.-grey {
  background: #f6f7fb;
}

.block h2 {
  margin-top: 1rem;
  text-align: center;
}

.panel {
  background-color: #fff;
  padding: 1rem;
  margin-bottom: 2rem;
}

.panel.-primary-light {
  background-color: #FF6161;
  color: #fff;
}

.panel.-secondary {
  background: #7aaddc;
  color: #fff;
}

.panel.-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.panel.-between {
  justify-content: space-between;
}

.panel.-title {
  margin: 0;
}

/**
 * CONTENTS
 *
 * VARIABLES
 * Colors........... All website colors
 * Themes........... Themes for clinic
 * Typography....... Font-family, font-size, line-height
 * Buttons.......... Buttons informations
 * Form............. Input, select style
 * Media Queries ... Breakpoint for responsive
 * Grid ............ Grid col and gutter
 */
/*------------------------------------*\
    #FONTS
\*------------------------------------*/
/*------------------------------------*\
    #COLORS
\*------------------------------------*/
/* Box Shadow */
/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/
/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    #FORMS
\*------------------------------------*/
/*------------------------------------*\
    #MEDIA-QUERIES
\*------------------------------------*/
/*------------------------------------*\
    #MEDIA QUERIES
\*------------------------------------*/
/*------------------------------------*\
    #GRID
\*------------------------------------*/
/*
 * Unstyled list - remove margin, padding and list type
 */
.language-switcher__list,
.unstyled-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/* Make hidden text accessible by screen readers */

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
 * Clearfix: contain floats
 */

.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}


.clearfix:after {
  clear: both;
}

#body .btn, #body .language-switcher__trigger {
  border: solid 1px rgba(227, 0, 43, 0.5);
  color: #e3002b;
  background: #fff;
  transition: color 0.2s ease-out;
  margin: .5rem;
  border-radius: 0;
}

#body .btn:not([disabled]):hover, #body .language-switcher__trigger:not([disabled]):hover {
  color: #fff;
  background: #e3002b;
  text-decoration: none;
}

#body .btn.-table, #body .-table.language-switcher__trigger {
  font-size: inherit;
}

#body .btn.-no-border, #body .-no-border.language-switcher__trigger {
  border: none;
  background: none;
  color: inherit;
}

#body .btn.-no-border:not([disabled]):hover, #body .-no-border.language-switcher__trigger:not([disabled]):hover {
  color: inherit;
  background: none;
}

#body .btn.-primary, #body .-primary.language-switcher__trigger {
  border: solid 1px rgba(227, 0, 43, 0.5);
  color: #fff;
  background: #e3002b;
}

#body .btn.-primary.-opacity, #body .-primary.-opacity.language-switcher__trigger {
  background: rgba(227, 0, 43, 0.8);
}

#body .btn.-primary:not([disabled]):hover, #body .-primary.language-switcher__trigger:not([disabled]):hover {
  color: #e3002b;
  background: #fff;
}

#body .btn.-primary:not([disabled]):hover.-opacity, #body .-primary.language-switcher__trigger:not([disabled]):hover.-opacity {
  background: rgba(255, 255, 255, 0.8);
}

#body .btn.-primary-light, #body .-primary-light.language-switcher__trigger {
  border: solid 1px rgba(255, 97, 97, 0.5);
  color: #FF6161;
  background: #fff;
}

#body .btn.-primary-light:not([disabled]):hover, #body .-primary-light.language-switcher__trigger:not([disabled]):hover {
  color: #fff;
  background: #FF6161;
}

#body .div-btn-center {
  text-align: center;
  margin: auto;
  background: #fff;
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 500;
}

#body .div-btn-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
}

#body .div-btn-end span {
  text-transform: uppercase;
  color: #989fa5;
  margin-right: 1rem;
}

#body .index-form-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

#body .index-form-btn div {
  display: flex;
  align-items: baseline;
}

#body button:focus, #body button:hover {
  outline: 0;
}

#body button:active {
  outline: none;
  border: none;
}

/**
 * CONTENTS
 *
 * VARIABLES
 * Colors........... All website colors
 * Themes........... Themes for clinic
 * Typography....... Font-family, font-size, line-height
 * Buttons.......... Buttons informations
 * Form............. Input, select style
 * Media Queries ... Breakpoint for responsive
 * Grid ............ Grid col and gutter
 */
/*------------------------------------*\
    #FONTS
\*------------------------------------*/
/*------------------------------------*\
    #COLORS
\*------------------------------------*/
/* Box Shadow */
/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/
/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    #FORMS
\*------------------------------------*/
/*------------------------------------*\
    #MEDIA-QUERIES
\*------------------------------------*/
/*------------------------------------*\
    #MEDIA QUERIES
\*------------------------------------*/
/*------------------------------------*\
    #GRID
\*------------------------------------*/
/*
 * Unstyled list - remove margin, padding and list type
 */
.language-switcher__list,
.unstyled-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/* Make hidden text accessible by screen readers */

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
 * Clearfix: contain floats
 */

.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}


.clearfix:after {
  clear: both;
}

/*------------------------------------*\
    #LANGUAGE SWITCHER
\*------------------------------------*/
.language-switcher {
  position: relative;
  text-align: right;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.language-switcher__trigger {
  position: relative;
  background-color: transparent;
  color: #fff;
  padding: .5rem 1.5rem .5rem .9rem;
  transition: color .3s;
  min-width: unset;
  font-size: 13px;
}

.language-switcher__trigger.-dark {
  color: #333333;
}

.language-switcher__trigger:after {
  content: '';
  position: absolute;
  right: .5rem;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  width: .7rem;
  height: .7rem;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj4NCjxwYXRoIGZpbGw9IiMyNDUzNkUiIGQ9Ik0yNTYuMSwyMTQuMWwxNzQsMTY3YzQsNCwxMSw0LDE2LDBsMzAtMzBjNS00LDUtMTEsMS0xNmwtMjEzLTIwNGMtMi0yLTUtMy04LTNzLTYsMS04LDNsLTIxMywyMDQNCgljLTQsNS00LDEyLDAsMTZsMzEsMzBjNCw0LDExLDQsMTYsMEwyNTYuMSwyMTQuMXoiLz4NCjwvc3ZnPg0K");
  fill: #fff;
  background-position: center center;
  transition: transform 250ms ease-in, background .3s;
  font-weight: bold;
}

.language-switcher__trigger[aria-expanded="true"]:after {
  transform: translateY(-50%);
}

.language-switcher__trigger:hover, .language-switcher__trigger:focus {
  opacity: .7;
  outline: none;
}

.language-switcher__trigger:hover:after, .language-switcher__trigger:focus:after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj4NCjxwYXRoIGZpbGw9IiNCREQ0MkEiIGQ9Ik0yNTYuMSwyMTQuMWwxNzQsMTY3YzQsNCwxMSw0LDE2LDBsMzAtMzBjNS00LDUtMTEsMS0xNmwtMjEzLTIwNGMtMi0yLTUtMy04LTNzLTYsMS04LDNsLTIxMywyMDQNCgljLTQsNS00LDEyLDAsMTZsMzEsMzBjNCw0LDExLDQsMTYsMEwyNTYuMSwyMTQuMXoiLz4NCjwvc3ZnPg0K");
}

.language-switcher__list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 100;
  text-align: center;
  background-color: #fff;
}

.language-switcher__list[aria-hidden="true"] {
  display: none;
}

.language-switcher__list a {
  display: block;
  background-color: transparent;
  padding: .5rem 0;
  text-decoration: none;
  font-weight: bold;
}

.language-switcher__list a:hover, .language-switcher__list a:focus {
  background-color: #eeeeee;
  text-decoration: none;
}

#intro-block {
  padding-top: 1rem;
}

@media (min-width: 48em) {
  #intro-block {
    padding-top: 3rem;
  }
}

#infos-block em {
  font-size: 20px;
}

#infos-block table {
  padding: 1rem;
}

#infos-block table thead tr {
  background-color: #dee2e6;
}

#infos-block table thead tr th {
  text-align: center;
}

#infos-block table td {
  text-align: center;
}

@media (max-width: 47.99em) {
  #infos-block table {
    width: 100%;
    overflow-x: auto;
    display: block;
  }
}

#benefits-block #benefits-img {
  position: relative;
  min-height: 400px;
}

#benefits-block #benefits-img img {
  width: calc(100% - 50%);
  position: absolute;
  top: 100px;
}

#benefits-block #benefits-img img:nth-child(2) {
  top: 0;
  right: 70px;
}

#prevention-block {
  background-image: url("../../medias/img/banner_prevention.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 41vh;
  width: 100%;
}

#prevention-block .container {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}

#prevention-block .container div:first-child {
  text-align: center;
  margin-top: 5rem;
}

#prevention-block .container div:first-child h2 {
  color: #fff;
}

@media (min-width: 75em) {
  #prevention-block .container {
    justify-content: flex-end;
  }
}

#test-block .panel.-secondary {
  text-align: center;
}

#test-block .panel.-secondary p {
  margin-bottom: .2rem;
  text-align: left;
  font-size: 13px;
  font-weight: 300;
}

#test-block .panel.-secondary .tel {
  margin-top: 1rem;
}

#test-block .panel.-secondary a {
  font-size: 13px;
}

#test-block .panel.-secondary h3 {
  text-align: center;
  color: #fff;
  margin-bottom: 1rem;
}

#test-block .panel.-secondary a, #test-block .panel.-secondary a:visited {
  color: #fff;
}

#test-block .panel.-secondary a:hover {
  color: #e3002b;
}

#test-block .panel.-secondary a.website-button {
  margin-bottom: -2.5rem;
}

#test-block .panel.-secondary .website-div {
  display: flex;
  margin-bottom: -2.5rem;
  justify-content: center;
}
